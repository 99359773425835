import React, {useEffect} from "react";
import {GoClippy} from "react-icons/go";
import copyToClipboard from "clipboard-copy";

import Layout from "../../components/layout";
import {SupportBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {CodeSnippet} from "../../components/snippet";
import {ApiLink} from "../../components/link";

const pageTitle = "FAQ";

const questions = [
    {
        key: 'linking-no-lib',
        tags: ['Linking'],
        question:
            <>
                When linking a program that uses SQLAPI++ I got an error:
                {' '}<code>unresolved external symbol "__declspec(dllimport) public: virtual __thiscall ..."</code><br/>
                What is missing?
            </>,
        answer:
            <>
                <p>
                    Probably you forgot to include sqlapi.lib
                    (or other SQLAPI++ library, depends on the compiler and/or
                    static/dynamic version) into the project. To link with SQLAPI++ library
                    you have to include sqlapi.lib into you project (as well as *.cpp)
                </p>
                <p>
                    For example, in Visual C++ you have to do the following:
                </p>
                <ol>
                    <li>In workspace tree select the project that you link with SQLAPI++</li>
                    <li>Use "Add Files to Project..." command on the right click menu and add sqlapi.lib to you
                        project
                    </li>
                    <li>Press F7 button to compile and link you project</li>
                </ol>
                <ul className="my-0">
                    <li><strong>sqlapi.lib</strong> should be used
                        when linking with dynamic version of SQLAPI++ using Microsoft Visual C++
                    </li>
                    <li><strong>sqlapis.lib</strong> to link with a static version using MSVC++</li>
                    <li>
                        <strong>sqlapib.lib</strong> to link with a dynamic version using Borland C++ compiler
                    </li>
                    <li>
                        <strong>sqlapibs.lib</strong> to link with a static version using Borland C++ compiler
                    </li>
                    <li>
                        <strong>sqlapibb.lib</strong> to link with a dynamic version using Borland C++ Builder
                        compiler
                    </li>
                    <li>
                        <strong>sqlapibbs.lib</strong> to link with a static version using Borland C++ Builder
                        compiler
                    </li>
                    <li>To link with a debug version use appropriate lib file that ends with 'd'</li>
                </ul>
            </>
    },
    {
        key: 'sybase-sp-return',
        db: ['Sybase'],
        tags: ['Stored Procedure'],
        question: 'How can I get status return code from stored procedure on Sybase?',
        answer:
            <>
                <p>
                    <span>SQLAPI++ library automatically creates </span>
                    <ApiLink>SAParam</ApiLink> object
                    to represent procedure status return code.</p>
                <p className="mb-0">
                    <span>After a command execution you can check it value. This SAParam object's name is </span>
                    <code>"RETURN_VALUE"</code>
                    <span>, object's type is </span>
                    <code>SA_dtLong</code>
                    <span>, object's direction type is </span>
                    <code>SA_ParamReturn</code>.
                </p>
            </>
    },
    {
        key: 'sybase-sp-in-out',
        db: ['Sybase'],
        tags: ['Stored Procedure'],
        question:
            <>
                I have a stored procedure in Sybase ASE that has an input-output parameter. But when I call
                {' '}<code>SAParam::ParamDirType</code>{' '}
                method for this parameter it always returns
                {' '}<code>SA_ParamInput</code>,{' '}
                and when I try to get the parameter's value after the
                command execution I receive nothing.<br/>
                What is wrong?
            </>,
        answer:
            <>
                <p>
                    <strong>Sybase ASE</strong>{' '} server does not provide
                    information about parameter's direction type, that's why SQLAPI++
                    library defines all parameters (except status result code) as input
                    (<code>SA_ParamInput</code>). If you have input-output parameters in the procedure
                    you have to call
                    {' '}<ApiLink>SAParam::setParamDirType</ApiLink>{' '}
                    method for these parameters before command execution and set parameter's direction
                    type explicitly (<code>SA_ParamInputOutput</code>).
                </p>
                <p>
                    You shouldn't call this method for procedure status result code because
                    it is detected correctly (as {' '}<code>SA_ParamReturn</code>) by the library.
                </p>
                <p className="mb-0">
                    There is no need to call this method for {' '}<strong>Sybase ASA</strong>{' '}
                    because all parameters are detected correctly automatically (including
                    the direction type).
                </p>
            </>
    },
    {
        key: 'sybase-sybinit-err',
        db: ['Sybase'],
        tags: ['Connection'],
        question:
            <>
                Sometimes "sybinit.err" file is appended to at a successful SQLAPI++ connection to
                ASE/ASA using Open Client? Console application also outputs some
                warning(s) about context allocation failure.<br/>
                Is this indicative of a real problem I should worry about?
            </>,
        answer:
            <>
                <p>
                    SQLAPI++ has to set the version of Client-Library behavior
                    when it connects to Sybase. By default SQLAPI++ tries to set 12.5
                    behavior; if it fails, SQLAPI++ tries to set 11.0 behavior and so on
                    from the highest version to the lowest one until it succeeds. Finally
                    the connection is successful, but every time when it fails Sybase
                    server generates the appropriate error message and adds the same error
                    message to the "sybinit.err" file.
                </p>
                <p className="mb-0">
                    The solution is to set the <code>CS_VERSION</code> option to "Detect" using
                    {' '}<ApiLink>SAConnection::setOption</ApiLink>{' '}
                    method. In that case SQLAPI++ automatically detects the maximum
                    available version of behavior via the version of Client-Library.
                </p>
            </>
    },
    {
        key: 'rows-affected-incorrect',
        tags: ['RowsAffected'],
        question:
            <>
                I execute a "Select..." statement and then call
                {' '}<code>SACommand::RowsAffected()</code>{' '}
                method to determine the number of rows to be returned by the command, but the result is
                always incorrect (-1 or 0 most of the times).<br/>
                What is wrong?
            </>,
        answer:
            <>
                {' '}<ApiLink>SACommand::RowsAffected</ApiLink>{' '}
                is only applicable to INSERT/UPDATE/DELETE queries. It is database API limitation, not
                SQLAPI++'s.
                The only way to get to know result set size in advance is to execute "Select count(*)..."
                command.
            </>
    },
    {
        key: 'linking-wchar_t',
        tags: ['Linking', 'Visual Studio'],
        question:
            <>
                When linking a program that uses SQLAPI++ under Microsoft Visual Studio 2005 I got an error:
                {' '}<code>error LNK2001: unresolved external symbol "public: __thiscall
                SAString::SAString(wchar_t const *)"</code><br/>
                I do include sqlapiu.lib in the project. What's wrong?
            </>,
        answer:
            <>
                <p>
                    We provide SQLAPI++ binaries that are linked with Microsoft
                    Visual Studio 6. Visual C++ 2005 compiler turns the
                    {' '}<code>/Zc:wchar_t</code>{' '}
                    option on by default. This is incompatible with Visual C++ 6.
                </p>
                <p>
                    To avoid this problem you can turn off {' '}<code>/Zc:wchar_t</code>{' '}
                    option (use {' '}<code>/Zc:wchar_t-</code>{' '}
                    or look at the project options dialog ('Treat wchar_t as Build-in Type' item).
                </p>
                <p className="mb-0">
                    But the best option is to rebuild SQLAPI++ with Visual C++ 2005 compiler.
                </p>
            </>
    },
    {
        key: 'linking-vs-libs',
        tags: ['Linking', 'Visual Studio'],
        question:
            <>
                When linking a program with static SQLAPI++ library I got an error:
                {' '}<code>sqlapis.lib(samisc.obj) : error LNK2019: unresolved external symbol
                _GetFileVersionInfoA@16 referenced in function "void * __cdecl
                SAGetVersionInfo(char const *)" (?SAGetVersionInfo@@YAPAXPBD@Z)</code><br/>
                Do I need to include additional libraries to resolve this?
            </>,
        answer:
            <>
                Since SQLAPI++ 3.7.25 you also need the libraries: user32.lib,
                version.lib, oleaut32.lib and ole32.lib
            </>
    },
    {
        key: 'sql-server-linux',
        db: ['SQL Server'],
        tags: ['Connection', 'Linux'],
        question:
            <>
                How can I connect to Microsoft SQL Server from Linux using SQLAPI++?
            </>,
        answer:
            <>
                <p>
                    There is an
                    {' '}
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href="https://docs.microsoft.com/en-us/sql/connect/odbc/download-odbc-driver-for-sql-server">
                        SQL Server ODBC/SQLNCLI Driver for Linux
                    </a>
                    {' '}
                    that provides native connectivity from Linux to Microsoft SQL Server.
                </p>
                <p>
                    SQLAPI++ support this
                    client software but Microsoft releases this driver only for x86-64 Linux platforms.
                    When you want to run the software on 32-bit Linux or any other Unix-like system you must
                    use ODBC connection. You need ODBC driver manager (most known are
                    {' '}<a target="_blank" rel="noopener noreferrer" href="http://www.iodbc.org">iODBC</a>{' '}
                    or
                    {' '}<a target="_blank" rel="noopener noreferrer"
                            href="http://www.unixodbc.org/">unixODBC</a>)
                    and MSSQL ODBC driver
                    (<a target="_blank" rel="noopener noreferrer"
                        href="https://www.freetds.org/faq.html">FreeTDS</a>{' '}
                    ODBC driver or commercial
                    {' '}
                    <a href="http://www.easysoft.com/products/data_access/odbc-sql-server-driver/index.html">
                        EasySOFT
                    </a>
                    {' '}
                    one).
                </p>
                <p>
                    You must install and configure ODBC software. Often Linux distribution
                    maintainers split the software into several packages, for example Debian
                    Lenny includes the next iODBC and FreeTDS packages:
                </p>
                <ul>
                    <li><strong>iodbc</strong> - GTK+ config frontend for the iODBC Driver Manager</li>
                    <li><strong>libiodbc2-dev</strong> - iODBC Driver Manager (development files)</li>
                    <li><strong>libiodbc2</strong> - iODBC Driver Manager</li>
                    <li><strong>freetds-common</strong> - configuration files for FreeTDS SQL client libraries
                    </li>
                    <li>
                        <strong>freetds-dev</strong>
                        - MS SQL and Sybase client library (static libs and headers)
                    </li>
                    <li><strong>libct4</strong> - libraries for connecting to MS SQL and Sybase SQL servers</li>
                    <li><strong>libsybdb5</strong> - libraries for connecting to MS SQL and Sybase SQL servers
                    </li>
                    <li><strong>tdsodbc</strong> - ODBC driver for connecting to MS SQL and Sybase SQL servers
                    </li>
                </ul>
                <p>
                    It's enough to have installed only iodbc, libiodbc2,
                    freetds-common and tdsodbc packages. For the first time it's better to
                    configure and test DSN (any driver manager includes
                    console tool for command line SQL):
                </p>
                <p>
                    <code>
                        bedlam:~# cat ~/.odbc.ini<br/>
                        [ODBC Data Sources]<br/>
                        MSSQL = FreeTDS driver<br/>
                        [MSSQL]<br/>
                        Driver = /usr/lib/odbc/libtdsodbc.so<br/>
                        Server = BEDLAM-M\SQLEXP2008EN<br/>
                        Database = pubs<br/>
                        TDS_Version = 7.0
                    </code>
                </p>
                <p>
                    <code>
                        bedlam:~# cat ~/.odbcinst.ini<br/>
                        [ODBC Drivers]<br/>
                        FreeTDS driver = Installed<br/>
                        [FreeTDS driver]<br/>
                        Driver = /usr/lib/odbc/libtdsodbc.so<br/>
                        Setup = /usr/lib/odbc/libtdsS.so
                    </code>
                </p>
                <p>
                    iodbc package provides iodbctest tool I run with parameters to check the ODBC DSN status:
                </p>
                <p>
                    <code>
                        bedlam-home:~# iodbctest "DSN=MSSQL;UID=sa;PWD=xxx"<br/>
                        iODBC Demonstration program<br/>
                        This program shows an interactive SQL processor<br/>
                        Driver Manager: 03.52.0607.1008<br/>
                        Driver: 0.82 (libtdsodbc.so)<br/>
                        <br/>
                        SQL&gt;
                    </code>
                </p>
                <p>Then you can connect using SQLAPI++:</p>
                <p><code>con.Connect("MSSQL", "sa", "xxx", SA_ODBC_Client);</code></p>
                <p>or without DSN configured:</p>
                <p className="mb-0">
                    <code>
                        {`conn.Connect("DRIVER={FreeTDS
                                driver};SERVER=BEDLAM-M\\SQLEXP2008EN;DATABASE=pubs;TDS_Version=7.0", "sa", "xxx",
                                SA_ODBC_Client);`}
                    </code>
                </p>
            </>
    },
    {
        key: 'num-rows',
        tags: ['RowsAffected'],
        question:
            <>
                Is there a way to get the number of rows returned by a query?
            </>,
        answer:
            <>
                Generally it's impossible. Most DBMS can count the result
                set rows only when all rows are already cached at the client side. For such cases
                {' '}<ApiLink>SACommand::RowsAffected</ApiLink>{' '}
                method returns the result set row count.
            </>
    },
    {
        key: 'win-apply-diff',
        tags: ['Source Code', 'Windows'],
        question:
            <>
                I got the .diff files, but how can I apply them to my existing source code?
                Could you please help me?
            </>,
        answer:
            <>
                <p>
                    You need a
                    {' '}<a href="http://savannah.gnu.org/projects/patch/">GNU patch</a>{' '}
                    tool. Version for Win32 can be downloaded from
                    {' '}<a href="http://sourceforge.net/projects/gnuwin32/files/patch/">SouceForge</a>.
                    Then run it from the console like:<br/>
                </p>
                <code>
                    C:\SQLAPI\src&gt;patch.exe &lt; myClient.cpp.diff<br/>
                    patching file myClient.cpp<br/>
                    <br/>
                    C:\SQLAPI\src&gt;
                </code>
            </>
    },
    {
        key: 'linking-libdl',
        tags: ['Linking', 'Linux'],
        question:
            <>
                When compiling step1.cpp on Linux got an error:
                {' '}<code>libsqlapi.so: undefined reference to `dlerror', 'dlopen', 'dlsym'...</code><br/>
                Could you help?
            </>,
        answer:
            <>
                <p>
                    SQLAPI++ uses libdl library API to load DBMS API. So you should include this library when
                    you linking your binary file with SQLAPI, e.g.:
                </p>
                <p><code>g++ -I../inclide -L../lib step1.cpp -o step1.out -lsqlapi -ldl</code></p>
                <p>or with static SQLAPI without libpath:</p>
                <p className="mb-0">
                    <code>g++ -I../inclide step1.cpp -o step1.out ../lib/libsqlapi.a -lsqlapi -ldl</code>
                </p>
            </>
    },
    {
        key: 'mysql-commands',
        db: ['MySQL'],
        tags: ['SACommand', 'SAConnection'],
        question:
            <>
                Could I have multiple instances of
                {' '}<ApiLink>SACommand</ApiLink>{' '}
                in one
                {' '}<ApiLink>SAConnection</ApiLink>?
                I tested under MySQL database, keep SACommands being opened,
                and always get error
                {' '}<code>"Commands out of sync; you can't run this command now"</code>.
            </>,
        answer:
            <>
                <p>
                    Default MySQL API doesn't allow to have several opened result set per connection. This is
                    exactly what {' '}<code>mysql_use_result</code>{' '} does.
                    The connection is blocked until you fetch all result set rows. When you'd like to run
                    another command when there is
                    not fetched result set at the same connection you must use another solution. The simplest -
                    using MySQL 'store' result set:
                </p>
                <p>
                    <code>
                        SACommand cmd(&con, "select ...");<br/>
                        cmd.setOption("HandleResult") = "store";<br/>
                        cmd.Execute();<br/>
                        ...
                    </code>
                </p>
                <p>
                    Then SQLAPI uses
                    {' '}<code>mysql_store_result</code>{' '}
                    and all rows cached at he client side after
                    query is executed and you can fetch the result and execute another command at the same
                    connection. The not good thing - this solution uses local MySQL client data cache. So MySQL
                    guys added another feature - statement API. statement API allows to execute command but the
                    result set stored at the client side. SQLAPI++ can use this feature with 'SELECT' commands.
                    The limitation - the single, forward-only result set only:
                </p>
                <p>
                    <code>
                        SACommand cmd(&con, "select ...");<br/>
                        cmd.setOption("UseStatement") = "TRUE";<br/>
                        cmd.Execute();<br/>
                        ...
                    </code>
                </p>
            </>
    },
    {
        key: 'sql-server-busy',
        db: ['SQL Server'],
        tags: ['SACommand', 'SAConnection'],
        question:
            <>
                I am getting the error {' '}<code>"HY000 [Microsoft][SQL Server Native Client
                11.0] Connection is busy with results for another command"</code>.
            </>,
        answer:
            <>
                <blockquote>
                    "I have 1 SAConnection object and 2 SACommand objects. One of the SACommand
                    objects is simply stepping through the records in a table. The second object
                    is attempting to INSERT a new record into the same table, before we have
                    processed all of the records in the first command.<br/>
                    Is this not allowed or am I doing something wrong?"
                </blockquote>
                <p>
                    This is known SQL Server issue. SQL Server doesn't allow to have the several result sets at
                    the same connection by default (the same problem is actual also for Sybase ASE, MySQL, ...).
                    The possible solutions for SQL Server:
                </p>
                <ul className="mb-0">
                    <li>
                        <div>
                            using MARS_Connection option. This is global option for the connection. You can
                            turn it on like:
                        </div>
                        <code>
                            SAConnection con;<br/>
                            con.Connect(_TSA("bedlam-m\\sql2014en@test;MARS_Connection=yes"), _TSA(""),<br/>
                            _TSA(""), SA_SQLServer_Client);
                        </code>
                    </li>
                    <li>
                        <div>
                            using the server side cursor for executing
                            {' '}<ApiLink>SACommand</ApiLink>:
                        </div>
                        <code>
                            SACommand cmd(&con, _TSA("select ..."));<br/>
                            cmd.setOption(_TSA("SQL_ATTR_CURSOR_TYPE")) = _TSA("SQL_CURSOR_DYNAMIC");
                        </code>
                    </li>
                </ul>
            </>
    },
    {
        key: 'is-alive-connected',
        tags: ['isAlive', 'isConnected'],
        question:
            <>
                Could you please tell me what is the difference between isAlive and isConnected?
                How to use them properly?
            </>,
        answer:
            <>
                <p>
                    See below the simplified usage scenario:
                </p>
                <div>
                    <CodeSnippet>{`
    while(....)
    {
        try
        {
            if(!con.isConnected())
                con.Connect(...);
            // database operations below
            ...
        }
        catch(SAException& x)
        {
            if(con.isConnected())
            {
                if(!con.isAlive())
                {
                    try {
                        con.Disconnect();
                    } catch(SAException&) {
                        con.Destroy();
                    }
                }
            }
        }
    }`.trim()}
                    </CodeSnippet>
                </div>
            </>
    },
    {
        key: 'exit-crashes',
        tags: ['Linux', 'violation'],
        question:
            <>
                During the test of SQLAPI++ we encountered a SEGV violation inside 3rd party ODBC library during normal
                process exit (return).
            </>,
        answer:
            <>
                <div>
                    <CodeSnippet>{`
#0  0x0000000000000000 in ?? ()
Missing separate debuginfos, use: debuginfo-install glibc-2.17-260.el7.x86_64 keyutils-libs-1.5.8-3.el7.x86_64 krb5-libs-1.15.1-34.el7.x86_64 libaio-0.3.109-13.el7.x86_64 libcom_err-1.42.9-13.el7.x86_64 libgcc-4.8.5-36.el7.x86_64 libgsasl-1.8.0-8.el7.x86_64 libidn-1.28-4.el7.x86_64 libntlm-1.3-0.6.el7.x86_64 libselinux-2.5-14.1.el7.x86_64 libstdc++-4.8.5-36.el7.x86_64 libuuid-2.23.2-59.el7.x86_64 libxml2-2.9.1-6.el7_2.3.x86_64 numactl-libs-2.0.9-7.el7.x86_64 openssl-libs-1.0.2k-16.el7.x86_64 pcre-8.32-17.el7.x86_64 protobuf-2.5.0-8.el7.x86_64 xz-libs-5.2.2-1.el7.x86_64 zlib-1.2.7-18.el7.x86_64
(gdb) where
#0  0x0000000000000000 in ?? ()
#1  0x00007f885bc747b8 in CFmsStatus::Log (this=this@entry=0xf61650, toStdErr=1 '\\001', toLog=toLog@entry=1) at /home/mnardi/devel/erm-server/ref/interfaces/fmsinterface/fmsstatus.cpp:1046
#2  0x0000000000417b84 in sigSegV (sig=<optimized out>) at /home/mnardi/devel/erm-server/ref/interfaces/fmsvarchive/ermvarchive_server.cpp:1109
#3  <signal handler called>
#4  0x00007f8843d3158e in client_dispose () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#5  0x00007f8843d3446b in sasl_dispose () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so#6  0x00007f8843cabf61 in sasl::TSaslClient::~TSaslClient() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#7  0x00007f8843caaa6b in boost_sb::detail::sp_counted_impl_p<sasl::TSaslClient>::dispose() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#8  0x00007f8843cac3a3 in apache::thrift::transport::TSaslTransport::~TSaslTransport() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#9  0x00007f8843caab18 in Simba::ThriftExtension::TETSaslClientTransport::~TETSaslClientTransport() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#10 0x00007f8842f3121f in boost_sb::detail::sp_counted_impl_p<Simba::ThriftExtension::TETSaslClientTransport>::dispose() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#11 0x00007f8842f3211a in apache::thrift::protocol::TBinaryProtocolT<apache::thrift::transport::TTransport>::~TBinaryProtocolT() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#12 0x00007f8842f31245 in boost_sb::detail::sp_counted_impl_p<apache: <apache::thrift::transport::TTransport> >::dispose() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#13 0x00007f8842ec07dd in boost_sb::detail::sp_counted_base::release() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#14 0x00007f8843050479 in apache::hive::service::cli::thrift::TCLIServiceClient::~TCLIServiceClient() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#15 0x00007f8842fa5ce5 in Simba::Hardy::HardyTCLIServiceThreadSafeClient::~HardyTCLIServiceThreadSafeClient() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#16 0x00007f8842fa5d19 in Simba::Hardy::HardyTCLIServiceThreadSafeClient::~HardyTCLIServiceThreadSafeClient() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#17 0x00007f8842f369ed in Simba::Hardy::HardyHiveCxnPool::~HardyHiveCxnPool() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#18 0x00007f8842f2ca2b in Simba::Hardy::HardyHiveClientFactory::~HardyHiveClientFactory() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#19 0x00007f8842eaee0f in Simba::Hardy::HardyConnection::Disconnect() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#20 0x00007f884342b34c in Simba::ODBC::ConnectionState4::SQLDisconnect(Simba::ODBC::Connection*) () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#21 0x00007f884341f1df in Simba::ODBC::Connection::SQLDisconnect() () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#22 0x00007f88433e51ba in short DoTask<Simba::ODBC::SQLDisconnectTask>(char const*, void*, Simba::ODBC::SQLDisconnectTask::TaskParameters&) () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#23 0x00007f88433d3b6c in SQLDisconnect () from /opt/cloudera/hiveodbc/lib/64/libclouderahiveodbc64.so
#24 0x00007f885d644a82 in SQLDisconnect (connection_handle=0x108c930) at SQLDisconnect.c:351
#25 0x00007f885d37d21a in IodbcConnection::Destroy (this=0x10afd80) at odbcClient.cpp:594
#26 0x00007f885d34b8f4 in SAConnection::Destroy (this=0x10996c0) at SQLAPI.cpp:1556
#27 0x00007f885d349c2c in SAPI::~SAPI (this=0x7f885d6318a0 <g_odbcAPI>, __in_chrg=<optimized out>) at SQLAPI.cpp:863
#28 0x00007f885a48eeda in __cxa_finalize () from /lib64/libc.so.6
#29 0x00007f885d348be3 in __do_global_dtors_aux () from /app/ermqa/erm/lib/libsqlapi.so
#30 0x00007ffe0df36670 in ?? ()
#31 0x00007f886197cfca in _dl_fini () from /lib64/ld-linux-x86-64.so.2
Backtrace stopped: frame did not save the PC
(gdb) quit`.trim()}
                    </CodeSnippet>
                </div>
                <p>
                    If you use 'exit(x)' function in your code that terminates the main process then local object
                    destructors
                    are not called and related SACommand/SAConnection are still opened. But C++ library processes exit()
                    call
                    and destroys all global objects - such as default SAPI ones used inside SQLAPI.
                </p>
                <p>
                    This doesn't look like a problem but since SQLAPI++ 5.x this global SAPI decides to close/destroy
                    all linked
                    SAConnection/SACommand. Meanwhile, it does this _after_ loaded DBMS API can clear its resources
                    using
                    atexit(). In the end, DBMS API function is called _after_ all resources are cleaned and almost any
                    such
                    function crashes.
                </p>
                <p>
                    You must clear all SQLAPI++ related resources (by executing SAPI::setClient(SA_Client_NotSpecified)
                    for
                    global/default SAPI instances) before calling exit(x).
                </p>
            </>
    }
];

const Page = () => {

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const section = document.querySelector(hash);
            const details = section?.querySelector('details');
            if (details) {
                details.setAttribute('open', 'true');
            }
            if (section) {
                section.scrollIntoView();
            }
        }
    });

    return (
        <Layout pageTitle={pageTitle}
                pageNav={<Breadcrumb activeName={pageTitle}/>}
        >
            {questions.map(({key, db = [], tags = [], question, answer}, index) => (
                <section key={key}
                         id={key}
                         className={`pb-2 mb-2 ${index < questions.length - 1 ? 'border-bottom' : ''} border-secondary hover-container`}>

                    <button type="button"
                            className="float-right btn btn-sm btn-light hover-target"
                            title="Copy this question's permalink"
                            onClick={async () => {
                                const [uri] = window.location.href.split("#");
                                await copyToClipboard(`${uri}#${key}`);
                            }}>
                        <GoClippy/>
                    </button>

                    {db.map((tag) => (
                        [' ', <span key={tag} className="badge badge-primary">{tag}</span>, ' ']
                    ))}
                    {tags.map((tag) => (
                        [' ', <span key={tag} className="badge badge-secondary">{tag}</span>, ' ']
                    ))}
                    <details>
                        <summary>{question}</summary>
                        <div className="py-2">{answer}</div>
                    </details>
                </section>
            ))}
        </Layout>
    );
};

export default Page;
